<template>
  <BaseList :list-query="list" route="period" locale-section="pages.periods" />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "PeriodList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      list: gql`
        query list {
          list: periods {
            name: period
            period
            id
            tpq
            taq
            en
            sv
          }
        }
      `
    };
  }
};
</script>
